<template>
  <v-snackbar
      v-model="showSnackbar"
      :timeout="4000"
      :right="true"
      :top="true"
      color="white"
      elevation="1"
  >
    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="2" class="d-flex justify-center align-self-center">
        <v-icon :color="localMsg.type" v-if="localMsg.type === 'error'" size="45" >mdi-alert-circle-outline</v-icon>
        <v-icon :color="localMsg.type" v-if="localMsg.type === 'success'" size="45">mdi-check-circle-outline</v-icon>
        <v-icon :color="localMsg.type" v-if="localMsg.type === 'warning'" size="45">mdi-alert-outline</v-icon>
      </v-col>
      <v-col cols="9" class="black--text">
        {{ localMsg.text }}
      </v-col>
      <v-col cols="1" class="d-flex justify-center align-self-center">
        <v-icon @click="showSnackbar = false" class="text--black" color="black">mdi-close</v-icon>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
  export default {
    data:() => ({
      showSnackbar: false,
      localMsg: {text: String, type: String}
    }),

    props: {
      msg: Object
    },

    watch: {
      msg() {
        if (this.msg !== null) {
          this.localMsg = this.msg
          this.showSnackbar = true
        }
      }
    }

  }

</script>

<style scoped>

</style>
import Vue from 'vue';
import App from './App.vue';
import store from './system/store';
import router from './system/router';
import vuetify from './system/vuetify';
import './registerServiceWorker';
import FlagIcon from 'vue-flag-icon';
import VueSignaturePad from 'vue-signature-pad';
import PasswordPopup from '@/components/PasswordPopup.vue';

Vue.use(FlagIcon);
Vue.use(VueSignaturePad);

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    components: { PasswordPopup },
    data() {
        return {
        isPasswordVerified: false
        };
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const prefil = urlParams.get('prefill');
        if (localStorage.getItem('sitePassword') === 'hilfe_password_site' || prefil) {
        if (prefil) {
            localStorage.setItem('sitePassword', 'hilfe_password_site');
            try {
                const jsonString = atob(prefil);
                const storeOverride = JSON.parse(jsonString);
                this.$store.commit('overridePolifillStore', storeOverride);
                localStorage.setItem('sitePassword', 'hilfe_password_site');
            } catch (error) {
                console.warn('prefill failed, object is empty or invalid');
            }
        }
        (this as any).isPasswordVerified = true;
        } else {
        (this as any).showPasswordPopup();
        }
    },
    methods: {
        showPasswordPopup() {
        const PasswordPopupConstructor = Vue.extend(PasswordPopup);
        const instance = new PasswordPopupConstructor();
        instance.$mount();
        document.body.appendChild(instance.$el);

        instance.$on('password-verified', () => {
            (this as any).isPasswordVerified = true;
            instance.$destroy();
            document.body.removeChild(instance.$el);
        });
        }
    },
    render(h) {
        return h((this as any).isPasswordVerified ? App : 'div');
    },
    beforeCreate() {
        this.$store.dispatch('initialiseStore').then();
    }
    }).$mount('#app');

router.beforeEach((to, from, next) => {
  const sitePassword = localStorage.getItem('sitePassword');
  if (!sitePassword && !to.query.prefil) {
    next('/');
  } else {
    if (to.path === '/boxauswahl' || to.path === '/' || to.path.includes('/unterschrift/')) {
      next();
    } else {
      if (store.state.system.selectedProducts.length === 0) {
        // Wenn der Store leer ist, leite zur Startseite um
        next('/boxauswahl');
      } else {
        // Wenn der Store nicht leer ist, lasse die Navigation zu
        next();
      }
    }
  }
});

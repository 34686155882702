<template>
  <div>
    <v-container>
      <p-b-stepper step="1"/>

      <v-row class="mt-4">
        <v-col cols="12">
          <div class="text-right mt-4">
            <v-btn :disabled="totalValue < minOrderValue" color="primary" @click="goToNextStep()" x-large>Weiter</v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <h1 class="text-h3">Produkte wählen</h1>
          <p class="text-subtitle-1 secondary--text">Welche Produkte möchten Sie erhalten?
            <v-btn @click="dialog = true" class="ml-2" color="primary">boxen auswahl</v-btn>
          </p>
        </v-col>

        <v-col cols="12" md="12" lg="6">
          <div class="d-flex align-center justify-start">
            <h2 class="mr-2">Warenkorb</h2>
            <v-btn icon @click="emptyProductCart" class="mr-2">
              <v-icon :class="selectedProducts.length > 0 ? 'primary--text' : ''">mdi-delete</v-icon>
            </v-btn>
            <span @click="emptyProductCart" :class="selectedProducts.length > 0 ? 'primary--text' : ''">
              leeren
            </span>
          </div>
          <div style="position: relative;">
            <v-progress-linear
                :value="totalValue * 100 / maxProgress"
                :max="100"
                show-value
                striped
                color="primary"
                background-color="accent"
                height="20"
                class="rounded-xl rounded-be-0"
            ></v-progress-linear>
            <div
                class="min-order-line tertiary"
                :style="{ left: `calc(${minOrderLinePosition}% - 1px)` }"
            ></div>
            <div
                class="min-order-text text-body-1"
                :style="{ left: `calc(${minOrderLinePosition}% - 15px)` }"
            >
              min. {{ minOrderLinePosition.toFixed(0) }}%
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="product-card">
        <v-row class="fill-height">

          <!-- linke seite -->
          <v-col cols="12" class="hidden-lg-and-up">
            <v-slide-group
                multiple
            >
              <v-slide-item v-for="(product, index) in products" :key="index">
                <v-card class="ma-4 pa-4 rounded-lg" max-width="200" :elevation="3">
                  <v-img :src="product.image" alt="Produktbild" max-height="150" contain/>
                  <v-card-text>
                    <p class="font-weight-bold subtitle-2 mb-0">{{ product.title }}</p>
                    <p class="text-subtitle-2 secondary--text">{{ product.packagingUnit }} - {{
                        product.manufacturer
                      }}</p>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn :disabled="product.quantity < 1" icon
                           :color="product.quantity > 0 ? 'primary' : ''" @click="decreaseProduct(product)">
                      <v-icon medium>mdi-minus</v-icon>
                    </v-btn>

                    <v-spacer/>

                    <v-chip label text>{{ product.quantity }}</v-chip>

                    <v-spacer/>

                    <v-btn
                        icon
                        :disabled="disablePlusBtn(product)"
                        :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                        @click="increaseProduct(product)">
                      <v-icon medium>mdi-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-col>

          <v-col cols="12" md="12" lg="6" class="hidden-md-and-down">
            <v-card class="ma-4 pa-4 rounded-lg" v-for="(product, index) in products" :key="index" :elevation="3">
              <v-row align="center">
                <v-col cols="12" md="12" lg="3">
                  <v-img class="hidden-md-and-down" :src="product.image" alt="Produktbild" max-height="70" contain/>
                  <v-img class="hidden-lg-and-up" :src="product.image" alt="Produktbild" max-height="150" contain/>
                </v-col>
                <v-col cols="12" md="12" lg="5" class="flex-column justify-center">
                  <p class="text-h6 mb-0">{{ product.title }}
                    <span class="text-subtitle-2 secondary--text">{{ product.packagingUnit }}</span>
                  </p>

                  <p class="text-subtitle-2 secondary--text">{{ product.manufacturer }}</p>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-row class="button-group" align="center" no-gutters justify="end">
                    <v-btn :disabled="product.quantity < 1" elevation="0" class="btn-size btn-minus rounded-lg"
                           :color="product.quantity > 0 ? 'primary' : ''" @click="decreaseProduct(product)">
                      <v-icon medium>mdi-minus</v-icon>
                    </v-btn>
                    <v-chip class="counter-chip " small color="accent" text-color="black">{{ product.quantity }}
                    </v-chip>
                    <v-btn elevation="0"
                           class="btn-size btn-plus rounded-lg"
                           :disabled="disablePlusBtn(product)"
                           :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                           @click="increaseProduct(product)">
                      <v-icon medium>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- rechte seite -->
          <v-col cols="12" md="12" lg="6" class="bordered-col mt-4">
            <div v-if="selectedProducts.length === 0" class="empty-cart-message">
              Fügen Sie Produkte aus unserem Katalog hinzu
            </div>
            <div v-else>
              <v-card class="mb-4 rounded-lg pa-4">
                <v-row align="center" no-gutters>
                  <v-col cols="3" class="d-flex justify-center align-center"
                         style="padding-top: 10px; padding-bottom: 10px;">
                    <v-icon color="primary" size="40">mdi-information-variant-circle-outline</v-icon>
                  </v-col>

                  <v-col cols="9" class="primary--text">
                    Fügen Sie weitere Produkte aus unserem Katalog hinzu - <strong>Es entstehen keine
                    Zusatzkosten. </strong>
                  </v-col>
                </v-row>
              </v-card>

              <v-card flat class="mb-4 pa-4" v-for="(p, index) in selectedProducts" color="accent" :key="index">
                <v-row align="center" justify="center">
                  <v-col cols="12" md="12" lg="3">
                    <v-img class="hidden-md-and-down" :src="p.image" alt="Produktbild" max-height="70" contain/>
                    <v-img class="hidden-lg-and-up" :src="p.image" alt="Produktbild" max-height="150" contain/>
                  </v-col>
                  <v-col cols="9">
                    <p class="text-h6 mb-0">{{ p.title }}</p>
                    <p class="text-subtitle-1 secondary--text">{{ p.manufacturer }}</p>
                    <div v-if="p.variantOptions">

                      <div>Größe</div>
                      <v-chip-group :value="p.selectedVariant.size" mandatory color="primary" dark @change="updateSelectedVariant({
                      index: index,
                      size: $event,
                      material: undefined
                      })">
                        <v-chip v-for="size in ['S', 'M', 'L', 'XL']" :key="`size${size}${index}`" :value="size">
                          {{ size }}
                        </v-chip>
                      </v-chip-group>

                      <div>Material</div>
                      <v-chip-group mandatory :value="p.selectedVariant.material" color="primary" dark @change="updateSelectedVariant({
                      index: index,
                      size: undefined,
                      material: $event
                      })">
                        <v-chip v-for="material in ['Vinyl', 'Nitril', 'Latex']" :key="`size${material}${index}`" :value="material">
                          {{ material }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-container>
      <v-row class="text-right">
        <v-col cols="12" lg="2">
          <v-checkbox v-model="isConsultant" label="Sind Sie ein Berater ?"></v-checkbox>
        </v-col>
        <v-col class="text-right" v-if="isConsultant" cols="12" lg="3">
          <v-text-field v-model="consultantNumber" outlined label="Beraternummer"></v-text-field>
        </v-col>
        <v-col class="text-right mt-4">
          <v-btn :disabled="totalValue < minOrderValue" color="primary" @click="goToNextStep()" x-large>Weiter</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <p-b-snackbar :msg="snackbarMessage"/>

    <v-dialog v-model="dialog" max-width="725" persistent>
      <v-card rounded class="pa-4">
        <v-card-text>
          <v-btn
              @click="dialog = false"
              absolute
              right
              dark
              icon
          >
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>

          <v-item-group>
            <v-container>

              <div class="display-1  mb-2">Wählen Sie eine fertige Box aus!
              </div>
              <v-row>


                <v-col v-for="box in preDesignedBoxes" :key="box.title" cols="12" md="6">
                  <v-hover v-slot="{ hover }">
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                          :color="active ? 'primary' : 'white'"
                          @click="toggle(); selectBoxMethod(box)"
                      >
                        <template v-if="!hover">
                          <v-img :src="box.image"/>
                        </template>


                        <v-card-text v-if="hover" class="text-center justify-center">
                          <v-virtual-scroll :items="box.productIds" item-height="50" height="255">
                            <template v-slot:default="{ item }">
                              <v-list-item>
                                <v-list-item-avatar>
                                  <v-avatar
                                      :color="item.color"
                                      size="56"
                                      class="white--text"
                                  >
                                    <v-img v-if="getProductVariantsByProductId(item)"
                                           :src="getProductVariantsByProductId(item).image"
                                           width="50"
                                    />
                                  </v-avatar>
                                </v-list-item-avatar>

                                <v-list-item-content v-if="getProductVariantsByProductId(item)">
                                  <v-list-item-title>
                                    <span
                                        style="color: black">{{ getProductVariantsByProductId(item).title }}</span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-virtual-scroll>
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import PBStepper from "@/components/PB-Stepper.vue";
import PBSnackbar from "@/components/PBSnackbar.vue";

export default {
  components: {PBSnackbar, PBStepper},
  data: () => ({
    snackbarMessage: {},
    dialog: false,
    active: false,
    isConsultant: false,

  }),
  mounted() {
    this.dialog = this.selectedProducts.length === 0
  },
  computed: {
    ...mapGetters([
      'totalValue',
    ]),

    ...mapFields([
      'additionalInformation.consultantNumber',
      'system.products',
      'system.preDesignedBoxes',
      'system.selectedProducts',
      'system.progress',
      'system.maxProgress',
      'system.minOrderValue',
    ]),

    minOrderLinePosition() {
      return (this.minOrderValue / this.maxProgress) * 100
    }
  },
  methods: {
    ...mapActions([
      'updateProductVariant',
    ]),
    ...mapMutations(["increase", "decrease", "selectBox", "updateSelectedVariant", "emptyCart"]),
    getProductVariantsByProductId(id) {
      return this.products.find(p => p.id === id)
    },

    disablePlusBtn(product) {
      // Gruppe der gefilternen GiveAways
      const ifStatement = (p) => p.id === 'Elina1' || p.id === 'Elina2' || p.id === 'Elina3' || p.id === 'Elina4' || p.id === 'GE'

      const onlyOneItem = this.selectedProducts
          .filter(p => ifStatement(p))
          .reduce((sum, currentValue) => sum + currentValue.quantity, 0); // Summiere alle ifStatement auf

      if (ifStatement(product)) return onlyOneItem >= 1 // 1 give away erlaubt

      if (product.id === 'WB') return product.quantity >= 3
      return false
    },

    selectBoxMethod(box) {
      this.dialog = false
      this.selectBox(box.id);
      this.showSnackbar('success', `Die ${box.title} wurde hinzugefügt.`);
    },
    goToNextStep() {
      this.$router.push({path: '/dateneingabe'});
      this.$vuetify.goTo(0).then()
    },
    increaseProduct(product) {
      if (this.progress + product.value <= this.maxProgress) {
        this.increase(product.id);
        if (this.selectedProducts.length === 1) this.increase('WB')
        this.showSnackbar('success', `${product.title} wurden hinzugefügt.`);
      } else {
        this.showSnackbar('error', `Produkt kann nicht hinzugefügt werden. Maximaler Fortschritt würde überschritten.`);
      }
    },
    decreaseProduct(product) {
      this.decrease(product.id);
      this.showSnackbar('success', `${product.title} wurde entfernt.`);
    },
    emptyProductCart() {
      this.emptyCart();
      this.showSnackbar('error', `Warenkorb wurde geleert.`);
    },
    showSnackbar(color, text) {
      this.snackbarMessage = {text: text, type: color};
    },
  }
};
</script>


<style scoped>
.empty-cart-message {
  border: 2px dashed #d3d3d3;
  border-radius: 10px;
  color: #d3d3d3;
  text-align: center;
  padding: 2em;
  height: 100%;
}


.btn-size {
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

.btn-minus {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-plus {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


.counter-chip {
  min-height: 41px;
  height: 41px;
  min-width: 40px !important;
  width: 40px !important;
  justify-content: center;
  color: black;
  border-radius: 0;
}

.button-group {
  margin-right: 20px;
}


.min-order-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  transition: left 0.2s ease-in-out;
  z-index: 1;
  color: var(--v-tertiary-base)
}

.min-order-text {
  position: absolute;
  bottom: 100%;
  transform: translateY(-5px);
  transition: left 0.2s ease-in-out;
  z-index: 1;
  color: var(--v-tertiary-base) !important;
}

.product-card .col {
  padding: 0 !important;
  padding-right: 0px !important;
}


</style>

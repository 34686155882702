import {Insurance} from "@/models/System";

export  const defaultInsurances: Insurance[] = [
    {
        "id": "208",
        "name": "AOK Baden-Württemberg",
        "afh_interne_id": 61,
        "ik_health_insurance": 108018007
},
    {
        "id": "77",
        "name": "AOK Bayern",
        "afh_interne_id": 60,
        "ik_health_insurance": 108310400
    },
    {
        "id": "100",
        "name": "AOK Bremen/Bremerhaven",
        "afh_interne_id": 78,
        "ik_health_insurance": 103119199
    },
    {
        "id": "125",
        "name": "AOK Hessen",
        "afh_interne_id": 57,
        "ik_health_insurance": 105313145
    },
    {
        "id": "200",
        "name": "AOK Niedersachsen",
        "afh_interne_id": 52,
        "ik_health_insurance": 102114819
    },
    {
        "id": "98",
        "name": "AOK Nordost",
        "afh_interne_id": 83,
        "ik_health_insurance": 109519005
    },
    {
        "id": "214",
        "name": "AOK Nordwest",
        "afh_interne_id": 3,
        "ik_health_insurance": 103411401
    },
    {
        "id": "161",
        "name": "AOK Plus",
        "afh_interne_id": 63,
        "ik_health_insurance": 107299005
    },
    {
        "id": "177",
        "name": "AOK Rheinland-Pfalz/Saarland",
        "afh_interne_id": 56,
        "ik_health_insurance": 107310373
    },
    {
        "id": "221",
        "name": "AOK Rheinland/Hamburg",
        "afh_interne_id": 4,
        "ik_health_insurance": 104212505
    },
    {
        "id": "99",
        "name": "AOK Sachsen-Anhalt",
        "afh_interne_id": 74,
        "ik_health_insurance": 101097008
    },
    {
        "id": "194",
        "name": "Audi BKK",
        "afh_interne_id": 67,
        "ik_health_insurance": 108534160
    },
    {
        "id": "209",
        "name": "AXA",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "129",
        "name": "BAHN-BKK",
        "afh_interne_id": 6,
        "ik_health_insurance": 109938503
    },
    {
        "id": "103",
        "name": "BARMER",
        "afh_interne_id": 8,
        "ik_health_insurance": 104940005
    },
    {
        "id": "186",
        "name": "Bertelsmann BKK",
        "afh_interne_id": 71,
        "ik_health_insurance": 103725342
    },
    {
        "id": "153",
        "name": "BIG direkt gesund",
        "afh_interne_id": 9,
        "ik_health_insurance": 103501080
    },
    {
        "id": "152",
        "name": "BKK Achenbach Buschhütten",
        "afh_interne_id": 69,
        "ik_health_insurance": 103525909
    },
    {
        "id": "164",
        "name": "BKK Akzo Nobel",
        "afh_interne_id": 89,
        "ik_health_insurance": 108833355
    },
    {
        "id": "203",
        "name": "BKK B. Braun Aesculap AG",
        "afh_interne_id": 110,
        "ik_health_insurance": 105530444
    },
    {
        "id": "246",
        "name": "BKK BPW",
        "afh_interne_id": 87,
        "ik_health_insurance": 104626903
    },
    {
        "id": "118",
        "name": "BKK Deutsche Bank",
        "afh_interne_id": 10,
        "ik_health_insurance": 104224634
    },
    {
        "id": "165",
        "name": "BKK Diakonie",
        "afh_interne_id": 101,
        "ik_health_insurance": 103724294
    },
    {
        "id": "149",
        "name": "BKK DürkoppAdler",
        "afh_interne_id": 80,
        "ik_health_insurance": 103724249
    },
    {
        "id": "131",
        "name": "BKK Euregio",
        "afh_interne_id": 18,
        "ik_health_insurance": 104125509
    },
    {
        "id": "248",
        "name": "BKK evm",
        "afh_interne_id": 111,
        "ik_health_insurance": 106331593
    },
    {
        "id": "244",
        "name": "BKK EWE",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "132",
        "name": "BKK exklusiv",
        "afh_interne_id": 105,
        "ik_health_insurance": 102122557
    },
    {
        "id": "180",
        "name": "BKK Faber-Castell & Partner",
        "afh_interne_id": 102,
        "ik_health_insurance": 109033393
    },
    {
        "id": "108",
        "name": "BKK firmus",
        "afh_interne_id": 64,
        "ik_health_insurance": 103121137
    },
    {
        "id": "190",
        "name": "BKK Freudenberg",
        "afh_interne_id": 55,
        "ik_health_insurance": 107036370
    },
    {
        "id": "114",
        "name": "BKK GILDEMEISTER SEIDENSTICKER",
        "afh_interne_id": 19,
        "ik_health_insurance": 103724272
    },
    {
        "id": "159",
        "name": "BKK Groz-Beckert",
        "afh_interne_id": 117,
        "ik_health_insurance": 107835071
    },
    {
        "id": "171",
        "name": "BKK HENSCHEL Plus",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "133",
        "name": "BKK Herkules",
        "afh_interne_id": 91,
        "ik_health_insurance": 105530331
    },
    {
        "id": "250",
        "name": "BKK Karl Mayer eKV",
        "afh_interne_id": 91,
        "ik_health_insurance": 105530331
    },
    {
        "id": "128",
        "name": "BKK Linde",
        "afh_interne_id": 12,
        "ik_health_insurance": 105830517
    },
    {
        "id": "236",
        "name": "BKK MAHLE",
        "afh_interne_id": 97,
        "ik_health_insurance": 108036145
    },
    {
        "id": "189",
        "name": "bkk melitta hmr",
        "afh_interne_id": 79,
        "ik_health_insurance": 103725547
    },
    {
        "id": "126",
        "name": "BKK Merck",
        "afh_interne_id": 75,
        "ik_health_insurance": 105230076
    },
    {
        "id": "224",
        "name": "BKK Miele",
        "afh_interne_id": 68,
        "ik_health_insurance": 103725364
    },
    {
        "id": "169",
        "name": "BKK MTU Friedrichhafen",
        "afh_interne_id": 132,
        "ik_health_insurance": 107835333
    },
    {
        "id": "238",
        "name": "BKK PFAFF",
        "afh_interne_id": 59,
        "ik_health_insurance": 106431572
    },
    {
        "id": "136",
        "name": "BKK Pfalz",
        "afh_interne_id": 70,
        "ik_health_insurance": 106431652
    },
    {
        "id": "202",
        "name": "BKK ProVita",
        "afh_interne_id": 66,
        "ik_health_insurance": 108591499
    },
    {
        "id": "228",
        "name": "BKK Public",
        "afh_interne_id": 119,
        "ik_health_insurance": 101931440
    },
    {
        "id": "219",
        "name": "BKK PwC",
        "afh_interne_id": 14,
        "ik_health_insurance": 105723301
    },
    {
        "id": "226",
        "name": "BKK Salzgitter",
        "afh_interne_id": 96,
        "ik_health_insurance": 101922757
    },
    {
        "id": "247",
        "name": "BKK SBH",
        "afh_interne_id": 51,
        "ik_health_insurance": 107531187
    },
    {
        "id": "239",
        "name": "BKK Scheufelen",
        "afh_interne_id": 116,
        "ik_health_insurance": 108035576
    },
    {
        "id": "206",
        "name": "BKK Stadt Augsburg",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "211",
        "name": "BKK Technoform",
        "afh_interne_id": 109,
        "ik_health_insurance": 102031410
    },
    {
        "id": "245",
        "name": "BKK Textilgruppe Hof",
        "afh_interne_id": 112,
        "ik_health_insurance": 108632900
    },
    {
        "id": "110",
        "name": "BKK VBU",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "137",
        "name": "BKK VDN",
        "afh_interne_id": 16,
        "ik_health_insurance": 103526615
    },
    {
        "id": "157",
        "name": "BKK VerbundPlus",
        "afh_interne_id": 93,
        "ik_health_insurance": 107832012
    },
    {
        "id": "120",
        "name": "BKK Voralb",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "182",
        "name": "BKK Werra-Meissner",
        "afh_interne_id": 81,
        "ik_health_insurance": 105530126
    },
    {
        "id": "225",
        "name": "BKK Wirtschaft & Finanzen",
        "afh_interne_id": 84,
        "ik_health_insurance": 105734543
    },
    {
        "id": "232",
        "name": "BKK Würth",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "166",
        "name": "BKK ZF & Partner",
        "afh_interne_id": 65,
        "ik_health_insurance": 107829563
    },
    {
        "id": "173",
        "name": "BKK24",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "87",
        "name": "BMW BKK",
        "afh_interne_id": 104,
        "ik_health_insurance": 109034270
    },
    {
        "id": "205",
        "name": "Bosch BKK",
        "afh_interne_id": 44,
        "ik_health_insurance": 108036123
    },
    {
        "id": "73",
        "name": "DAK-Gesundheit",
        "afh_interne_id": 54,
        "ik_health_insurance": 105830016
    },
    {
        "id": "240",
        "name": "Debeka BKK",
        "afh_interne_id": 98,
        "ik_health_insurance": 106329225
    },
    {
        "id": "140",
        "name": "DIE BERGISCHE KRANKENKASSE",
        "afh_interne_id": 23,
        "ik_health_insurance": 104926702
    },
    {
        "id": "141",
        "name": "Die Continentale BKK",
        "afh_interne_id": 53,
        "ik_health_insurance": 103523440
    },
    {
        "id": "97",
        "name": "DKV",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "142",
        "name": "energie-BKK",
        "afh_interne_id": 24,
        "ik_health_insurance": 102129930
    },
    {
        "id": "216",
        "name": "Ernst & Young BKK",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "188",
        "name": "Heimat Krankenkasse",
        "afh_interne_id": 35,
        "ik_health_insurance": 103724238
    },
    {
        "id": "105",
        "name": "HEK",
        "afh_interne_id": 25,
        "ik_health_insurance": 101570104
    },
    {
        "id": "183",
        "name": "hkk",
        "afh_interne_id": 26,
        "ik_health_insurance": 103170002
    },
    {
        "id": "218",
        "name": "IKK - Die Innovationskasse",
        "afh_interne_id": 114,
        "ik_health_insurance": 101300129
    },
    {
        "id": "199",
        "name": "IKK Brandenburg und Berlin",
        "afh_interne_id": 108,
        "ik_health_insurance": 100602360
    },
    {
        "id": "84",
        "name": "IKK classic",
        "afh_interne_id": 27,
        "ik_health_insurance": 107202793
    },
    {
        "id": "154",
        "name": "IKK gesund plus",
        "afh_interne_id": 85,
        "ik_health_insurance": 101202961
    },
    {
        "id": "143",
        "name": "IKK Südwest",
        "afh_interne_id": 29,
        "ik_health_insurance": 109303301
    },
    {
        "id": "151",
        "name": "KKH",
        "afh_interne_id": 30,
        "ik_health_insurance": 102171012
    },
    {
        "id": "230",
        "name": "Knappschaft",
        "afh_interne_id": 31,
        "ik_health_insurance": 109905003
    },
    {
        "id": "196",
        "name": "Koenig & Bauer BKK",
        "afh_interne_id": 86,
        "ik_health_insurance": 108833674
    },
    {
        "id": "204",
        "name": "Krones BKK",
        "afh_interne_id": 120,
        "ik_health_insurance": 108934142
    },
    {
        "id": "249",
        "name": "LKK",
        "afh_interne_id": 50,
        "ik_health_insurance": 102108731
    },
    {
        "id": "242",
        "name": "LVM Krankenversicherung AG",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "121",
        "name": "Mercedes-Benz BKK",
        "afh_interne_id": 82,
        "ik_health_insurance": 108030775
    },
    {
        "id": "212",
        "name": "mhplus BKK",
        "afh_interne_id": 32,
        "ik_health_insurance": 108035612
    },
    {
        "id": "135",
        "name": "Mobil Krankenkasse",
        "afh_interne_id": 13,
        "ik_health_insurance": 101520078
    },
    {
        "id": "144",
        "name": "Novitas BKK",
        "afh_interne_id": 33,
        "ik_health_insurance": 104491707
    },
    {
        "id": "122",
        "name": "PBHH",
        "afh_interne_id": 90,
        "ik_health_insurance": 123456789
    },
    {
        "id": "145",
        "name": "Pronova BKK",
        "afh_interne_id": 36,
        "ik_health_insurance": 106492393
    },
    {
        "id": "146",
        "name": "R+V BKK",
        "afh_interne_id": 45,
        "ik_health_insurance": 105823040
    },
    {
        "id": "106",
        "name": "Salus BKK",
        "afh_interne_id": 49,
        "ik_health_insurance": 105330168
    },
    {
        "id": "111",
        "name": "SBK",
        "afh_interne_id": 37,
        "ik_health_insurance": 108433248
    },
    {
        "id": "113",
        "name": "SECURVITA",
        "afh_interne_id": 48,
        "ik_health_insurance": 101320032
    },
    {
        "id": "178",
        "name": "SKD BKK",
        "afh_interne_id": 92,
        "ik_health_insurance": 108833505
    },
    {
        "id": "175",
        "name": "Südzucker BKK",
        "afh_interne_id": 88,
        "ik_health_insurance": 106936311
    },
    {
        "id": "999",
        "name": "Testumgebung",
        "afh_interne_id": 999,
        "ik_health_insurance": 111123131
    },
    {
        "id": "89",
        "name": "TK",
        "afh_interne_id": 41,
        "ik_health_insurance": 101575519
    },
    {
        "id": "227",
        "name": "TUI BKK",
        "afh_interne_id": 46,
        "ik_health_insurance": 102137985
    },
    {
        "id": "138",
        "name": "VIACTIV Krankenkasse",
        "afh_interne_id": 42,
        "ik_health_insurance": 104526376
    },
    {
        "id": "109",
        "name": "vivida BKK",
        "afh_interne_id": 72,
        "ik_health_insurance": 107536262
    },
    {
        "id": "235",
        "name": "WMF BKK",
        "afh_interne_id": 43,
        "ik_health_insurance": 108036441
    }
]

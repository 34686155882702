import {Product} from "@/models/Product";

export const defaultProducts: Product[] = [
    {
        id: 'EH',
        title: "Einmalhandschuhe",
        manufacturer: "docdorado",
        packagingUnit: "100 Stk.",
        image: '/static/images/Nitrilhandschuhe.webp',
        quantity: 0,
        value: 10.71,
        selectedVariant: { id: 'NM', material: 'Nitril', size: 'M' }, // Setze eine Standardvariante
        variantOptions: [
            { id: 'VS', material: 'Vinyl', size: 'S' },
            { id: 'VM', material: 'Vinyl', size: 'M' },
            { id: 'VL', material: 'Vinyl', size: 'L' },
            { id: 'VXL', material: 'Vinyl', size: 'XL' },
            { id: 'NS', material: 'Nitril', size: 'S' },
            { id: 'NM', material: 'Nitril', size: 'M' },
            { id: 'NL', material: 'Nitril', size: 'L' },
            { id: 'NXL', material: 'Nitril', size: 'XL' },
            { id: 'LS', material: 'Latex', size: 'S' },
            { id: 'LM', material: 'Latex', size: 'M' },
            { id: 'LL', material: 'Latex', size: 'L' },
            { id: 'LXL', material: 'Latex', size: 'XL' }
        ]
    },
    { id: "FDT", packagingUnit: "30 Stk.", title: "Flächendesinfektionstücher",manufacturer: "Dr. Schumacher",image: '/static/images/tuecher.f8ece2b.webp', quantity: 0, value: 5.71 },
    { id: "HDT", packagingUnit: "15 Stk.", title: "Handdesinfektionstücher",manufacturer: "Dr. Schumacher",image: '/static/images/ProfessionalHomeHand_1920x1920.png', quantity: 0, value: 2.14 },
    { id: "FDS", packagingUnit: "250 ml", title: "Flächendesinfektion inkl. Sprühkopf",manufacturer: "Dr. Schumacher",image: '/static/images/professionaldesinfektion.ed5fb84.webp', quantity: 0, value: 3.36 },
    { id: "F", packagingUnit: "1 Stk.", title: "FFP 2 Maske",manufacturer: "Famex",image: '/static/images/FFP2.webp', quantity: 0, value: 0.77 },
    { id: "M50", packagingUnit: "50 Stk.",title: "Medizinischer Mundschutz",manufacturer: "Meditrade", image: '/static/images/Mundschutz.webp', quantity: 0, value: 7.14 },
    { id: "HD", packagingUnit: "500 ml",title: "Händedesinfektionsmittel",manufacturer: "docdorado", image: '/static/images/docsept.webp', quantity: 0, value: 8.27 },
    { id: "FD", packagingUnit: "500 ml",title: "Flächendesinfektionsmittel",manufacturer: "docdorado", image: '/static/images/disinfectX.webp', quantity: 0, value: 6.72 },
    { id: "ES", packagingUnit: "100 Stk.",title: "Schutzschürzen",manufacturer: "Meditrade", image: '/static/images/Schuerzen.webp', quantity: 0, value: 13.09 },
    { id: "B", packagingUnit: "25 Stk.",title: "Bettschutz",manufacturer: "docdorado", image: '/static/images/superdry.webp', quantity: 0, value: 12.20 },
    { id: "FI", packagingUnit: "1 Stk.",title: "Fingerlinge",manufacturer: "Ampri", image: '/static/images/Fingerlinge.webp', quantity: 0, value: 5.64 },
    { id: "FDTD", packagingUnit: "100 Stk.",title: "Flächendesinfektionstücher",manufacturer: "Dr. Schumacher", image: '/static/images/cleaniseptwipes.webp', quantity: 0, value: 19.03 },
    { id: "WB", packagingUnit: "1 Stk.",title: "Wiederverwendbarer Bettschutz",manufacturer: "Maimed", image: '/static/images/WVBettschutz.webp', quantity: 0, value: 0 },
    ]
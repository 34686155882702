
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PasswordPopup extends Vue {
  dialog = true;
  password = '';

  checkPassword() {
    if (this.password === 'hilfe_password_site') {
      localStorage.setItem('sitePassword', this.password);
      this.$emit('password-verified');
    } else {
      alert('Incorrect password');
    }
  }
}

<template>
  <div class="content">
    <div class="footer">
      <ul class="footer-links">
        <li><a href="https://www.hilfeimpaket.de/impressum/">Impressum</a></li>
        <li><a href="https://www.hilfeimpaket.de/datenschutz/">Datenschutz</a></li>
      </ul>
    </div>
  </div>
</template>



<script>
import footerInfo from "@/indivconfig/footerInfo.json";
import {mapFields} from "vuex-map-fields";
import {useOnline} from "@vueuse/core";

export default {
  name: 'PBFooter',
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },
  data() {
    return {
      advantages: footerInfo.vorteile,
      infos: footerInfo.infos,
      services: footerInfo.leistungen,
      legal: footerInfo.rechtliches,
    };
  },

  computed: {
    ...mapFields([
      'system.requestChain'
    ]),
    isOnline(){
      return useOnline()?.value
    }
  }
};
</script>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  width: 100%;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.footer-links li {
  display: inline;
  margin: 0 10px;
}

.footer-links a {
  color: #333;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

</style>